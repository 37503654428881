import React, { useState } from 'react';
import PageHeading from '../PageHeading';
import Button from '../Button';

import Spacing from '../Spacing';

import './BtcChecker.css';
import Div from '../Div';

const BtcChecker = () => {
  const [btcValue, setBtcValue] = useState('');
  const [btcRate, setBtcRate] = useState(null);
  const [error, setError] = useState(null);

  const handleCheckBtc = async () => {
    try {
      const response = await fetch('https://api.coingecko.com/api/v3/simple/price?ids=bitcoin&vs_currencies=CZK');
      const data = await response.json();
      const rate = data.bitcoin.czk;
      setBtcRate(rate);
      setError(null);
    } catch (error) {
      setError('Failed to fetch BTC rate');
      setBtcRate(null);
    }
  };

  const formatNumber = (number) => {
    return number.toLocaleString('cs-CZ', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  };

  return (
    <>
          <PageHeading
        title="Check BTC Value in CZK"
        bgSrc="/images/blog_hero_bg.jpeg"
        pageLinkText="BTC checker"
      />

<Spacing lg="145" md="80" />
<Div className="container">
    <div>
      <h1></h1>
      <input
        type="text"
        value={btcValue}
        onChange={(e) => setBtcValue(e.target.value)}
        placeholder="Enter SAT amount"
      />
      <Button btnOnClick={handleCheckBtc} btnText={"Check Value"} ></Button>
      {btcRate !== null && (
        <div>
          <p>BTC Rate: {formatNumber(btcRate)} CZK</p>
          <p>Value in CZK: <h3>{btcValue ? formatNumber(btcValue/100000000 * btcRate) : 0} CZK</h3></p>
        </div>
      )}
      {error && <p>{error}</p>}
    </div>
    </Div>
    </>
  );
};

export default BtcChecker;