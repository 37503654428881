export const bgImageData = 
    [
        "images/stablerecords/stablerecords-cute.webp",
        "images/stablerecords/stablerecords-rezije.webp",
        "/images/stablerecords/stablerecords-recordroom.webp",
        "/images/stablerecords/stablerecords-drums.webp",
        "/images/stablerecords/marshall.webp"

    ]

