// Data for the table
const equipmentListData = [
    {
      category: 'Mikrofony',
      items: [
        {
          vyrobce: 'Neumann',
          typ: 'TLM 103',
          popis: 'Legendární mikrofon – používám převážně na zpěvy, ale je skvělý i na akustiku nebo jako room mic pro bicí',
        },
        {
          vyrobce: 'Townsend/Universal audio',
          typ: 'Sphere L22',
          popis: 'Neuvěřitelný velkomembránový mikrofon. Díky duální kapsly je možný používat jak v mono tak i ve stereo režimu. Zatím nepřekonaný na zpěvy, akustickou kytaru nebo room mic na bicí. Více info zde.',
        },
        {
            vyrobce: 'Shure',
            typ: 'SM57',   
            popis: 'Klasika mezi dynamickými mikrofony. Využívám na kytarové zesilovače, snare nebo hi-hat.',
        },
        {
            vyrobce: 'Shure',
            typ: 'SM58',
            popis: 'Klasický dynamický mikrofon na zpěv.',
        },
        {
            vyrobce: 'AKG',
            typ: 'C414',
            popis: 'Velkomembránový kondenzátorový mikrofon. Skvělý na zpěv, akustickou kytaru nebo bicí.',
        },
        {
            vyrobce: 'AKG',
            typ: 'C451',
            popis: 'Malý kondenzátorový mikrofon. Používám na snare, hi-hat nebo akustickou kytaru.',
        },
        {
            vyrobce: 'Sennheiser',
            typ: 'MD421',
            popis: 'Dynamický mikrofon. Používám na toms nebo kytarové zesilovače.',
        },
        {
            vyrobce: 'Sennheiser',
            typ: 'MD441',
            popis: 'Dynamický mikrofon. Používám na zpěv nebo kytarové zesilovače.',
        },
        {
            vyrobce: 'AKG',
            typ: 'D112',
            popis: 'Dynamický mikrofon. Používám na kopák.',
        },
        {
            vyrobce: 'Warm Audio',
            typ: 'WA-84 pair',
            popis: 'Za mně dokonalá kopie tužek od Neumann. Akustická kytara nebo overheady jsou s nimy top.',
        },
        {
            vyrobce: 'sE Electronics',
            typ: 'V Kick',
            popis: 'Dynamický mikrofon na kopák.',
        }
        
      ],
    },
    {
      category: 'Mikrofonové předzesilovače a převodníky',
      items: [
        {
          vyrobce: 'Universal audio',
          typ: '2x Apollo 8x',
          popis: 'Srdce studia – Zvuková karta s úžasnými preampy a konvertory',
        },
        {
          vyrobce: 'Universal audio',
          typ: 'Apollo Twin',
          popis: 'Ovládání moniitorů plus dva další UAD preampy',
        },
        {
          vyrobce: 'Warm audio',
          typ: 'WA273',
          popis: 'Velmi povedená kopie legendárního NEVE 1073 by Warm audio',
        },
        {
          vyrobce: 'ART',
          typ: 'TPSII',
          popis: '2x lampový preamp',
        },
        {
          vyrobce: 'WarmAudio',
          typ: 'WA-2A',
          popis: 'HW optokompresor by warm audio',
        },
        {
          vyrobce: 'Focusrite',
          typ: 'Liquid saffire 56',
          popis: 'Dříve sloužil jako zvukovka dnes už jen jako 8 kanálový převodník na ADAT',
        },
        {
          vyrobce: 'Behringer',
          typ: 'Powerplay HA4000',
          popis: '4 kanálový sluchátkový zesilovač. Celkem máme k dispozici 7 sluchátkových cest.',
        },
        // ... more items
      ],
    },
    {
        category: 'Kytarové vybavení',
        items: [
          {
            vyrobce: 'Line 6',
            typ: 'Helix',
            popis: 'Simulace aparátů včetně kolekce impulse response',
          },
          {
            vyrobce: 'Salvation Audio',
            typ: 'MatchVox',
            popis: 'Preamp po vzoru Matchless a VOX',
          },
          {
            vyrobce: 'Salvation Audio',
            typ: 'Rhoadie',
            popis: 'Stará dobrá plexina',
          },
          {
            vyrobce: 'Orange',
            typ: '2×12 kabinet',
            popis: 'S dvěma Celestion Vintage 30',
          },
          {
            vyrobce: 'Coffee audio',
            typ: 'Mocha 112',
            popis: 'https://coffee-audio.com/mocha-112/',
          },
          {
            vyrobce: 'Fender',
            typ: 'Deluxe Reverb 68',
            popis: 'Klasický silverface',
          },
          {
            vyrobce: 'OldStep',
            typ: 'Kopie Mesa Boogie Lone Star',
            popis: 'https://oldstep.cz/',
          }
            


          // ... more items
      ],
    },
    {
    category: 'SW vybavení',
        items: [
            // Universal Audio Plugins
            {
                vyrobce: 'Apple',
                typ: 'Logic Pro X',
                popis: 'Srdce studia – DAW od Applu'
            },
            {
                vyrobce: 'Universal Audio',
                typ: '1176LN Limiting Amplifier',
                popis: 'Populární kompresor používaný na bicí a vokály.'
            },
            {
                vyrobce: 'Universal Audio',
                typ: 'LA-2A Leveling Amplifier',
                popis: 'Optický kompresor ideální pro vokály.'
            },
            {
                vyrobce: 'Universal Audio',
                typ: 'Pultec EQP-1A',
                popis: 'Pasivní ekvalizér známý pro své muzikální zkreslení.'
            },
            {
                vyrobce: 'Universal Audio',
                typ: 'Teletronix LA-2A',
                popis: 'Další verze optického kompresoru.'
            },
            {
                vyrobce: 'Universal Audio',
                typ: 'dbx 160',
                popis: 'VCA kompresor vhodný pro nástroje s vysokým tranzientním obsahem.'
            },
            {
                vyrobce: 'Universal Audio',
                typ: 'Shadow Hills Mastering Compressor',
                popis: 'Kombinuje optický a VCA kompresor pro mastering.'
            },
            {
                vyrobce: 'Universal Audio',
                typ: 'EMT 140 Classic Plate Reverb',
                popis: 'Reverb s charakteristickým "plate" zvukem.'
            },
            {
                vyrobce: 'Universal Audio',
                typ: 'Fairchild Tube Limiter Collection',
                popis: 'Vintage kompresory používané The Beatles a Pink Floyd.'
            },
            {
                vyrobce: 'Universal Audio',
                typ: 'Galaxy Tape Echo',
                popis: 'Emulace Roland RE-201 tape delay/spring reverb jednotky.'
            },
            // Slate Digital Plugins
            {
                vyrobce: 'Slate Digital',
                typ: 'FG-X Mastering Processor',
                popis: 'Masteringový procesor.'
            },
            {
                vyrobce: 'Slate Digital',
                typ: 'Virtual Mix Rack',
                popis: 'Modulární mixážní rack.'
            },
            {
                vyrobce: 'Slate Digital',
                typ: 'Virtual Tape Machines',
                popis: 'Emulace páskového stroje.'
            },
            {
                vyrobce: 'Slate Digital',
                typ: 'VerbSuite Classics',
                popis: 'Reverb plugin.'
            },
            {
                vyrobce: 'Slate Digital',
                typ: 'Repeater',
                popis: 'Delay plugin.'
            },
            {
                vyrobce: 'Slate Digital',
                typ: 'Fresh Air',
                popis: 'Enhancer.'
            },
            {
                vyrobce: 'Slate Digital',
                typ: 'Virtual Buss Compressors',
                popis: 'Set buss kompresorů.'
            },
            {
                vyrobce: 'Slate Digital',
                typ: 'FG-2A',
                popis: 'Optický kompresor.'
            },
            {
                vyrobce: 'Slate Digital',
                typ: 'Custom Opto',
                popis: 'Optický kompresor.'
            },
            {
                vyrobce: 'Slate Digital',
                typ: 'FG-Stress',
                popis: 'Emulace Empirical Labs Distressor.'
            },
            {
                vyrobce: 'Slate Digital',
                typ: 'Infinity EQ',
                popis: 'Equalizer.'
            },
            // iZotope Ozone 10 Plugins
            {
                vyrobce: 'iZotope',
                typ: 'Maximizer',
                popis: 'Limiter.'
            },
            {
                vyrobce: 'iZotope',
                typ: 'Equalizer',
                popis: 'Ekvalizér.'
            },
            {
                vyrobce: 'iZotope',
                typ: 'Dynamic EQ',
                popis: 'Dynamický ekvalizér.'
            },
            {
                vyrobce: 'iZotope',
                typ: 'Vintage Limiter',
                popis: 'Vintage limiter.'
            },
            {
                vyrobce: 'iZotope',
                typ: 'Exciter',
                popis: 'Harmonic exciter.'
            },
            {
                vyrobce: 'iZotope',
                typ: 'Imager',
                popis: 'Stereo imager.'
            },
            {
                vyrobce: 'iZotope',
                typ: 'Master Rebalance',
                popis: 'Nástroj pro úpravu mixu.'
            },
            {
                vyrobce: 'iZotope',
                typ: 'Low End Focus',
                popis: 'Nástroj pro úpravu nízkých frekvencí.'
            },
            // iZotope Nectar Plugins
            {
                vyrobce: 'iZotope',
                typ: 'Vocal Assistant',
                popis: 'Asistent pro zpracování vokálů.'
            },
            {
                vyrobce: 'iZotope',
                typ: 'Pitch Editor',
                popis: 'Editor ladění.'
            },
            {
                vyrobce: 'iZotope',
                typ: 'Breath Control',
                popis: 'Kontrola dechu.'
            },
            {
                vyrobce: 'iZotope',
                typ: 'Harmony',
                popis: 'Harmonizer.'
            },
            {
                vyrobce: 'iZotope',
                typ: 'Dimension',
                popis: 'Přidání prostoru.'
            },
            {
                vyrobce: 'iZotope',
                typ: 'De-Esser',
                popis: 'Odstranění sykavek.'
            },
            {
                vyrobce: 'iZotope',
                typ: 'Saturation',
                popis: 'Saturace.'
            },
            {
                vyrobce: 'iZotope',
                typ: 'Reverb',
                popis: 'Reverb.'
            },
            {
                vyrobce: 'iZotope',
                typ: 'Delay',
                popis: 'Delay.'
            },
            {
                vyrobce: 'iZotope',
                typ: 'Gate',
                popis: 'Gate.'
            },
            // SSL Plugins
            {
                vyrobce: 'SSL',
                typ: 'SSL Native Channel Strip',
                popis: 'Emulace channel stripu.'
            },
            {
                vyrobce: 'SSL',
                typ: 'SSL Native Bus Compressor',
                popis: 'Buss kompresor.'
            },
            {
                vyrobce: 'SSL',
                typ: 'SSL Native X-EQ 2',
                popis: 'Ekvalizér.'
            },
            {
                vyrobce: 'SSL',
                typ: 'SSL Native X-Comp',
                popis: 'Kompresor.'
            },
            {
                vyrobce: 'SSL',
                typ: 'SSL Native X-ValveComp',
                popis: 'Valve kompresor.'
            },
            {
                vyrobce: 'SSL',
                typ: 'SSL Native X-Saturator',
                popis: 'Saturátor.'
            }
        ],
    },
  ];

  export default equipmentListData;