import React, { useState, useEffect } from 'react';
import parse from 'html-react-parser';
import './hero.scss';
import Button from '../Button';
import Div from '../Div';
import VerticalLinks from '../VerticalLinks';

export default function Hero({
  title,
  subtitle,
  btnText,
  btnLink,
  scrollDownId,
  socialLinksHeading,
  heroSocialLinks,
  bgImageUrl,
  overlay = false,
}) {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % bgImageUrl.length);
    }, 5000); // mění obrázek každých 5 sekund

    return () => clearInterval(timer); // vyčistí interval při odmontování komponenty
  }, [bgImageUrl]);

  return (
    <Div className="cs-hero cs-style1 cs-bg cs-fixed_bg cs-shape_wrap_1">
    {bgImageUrl.map((url, index) => (
      <Div
        key={url}
        className={`cs-hero_background ${index === currentImageIndex ? 'visible' : ''}`}
        style={{ backgroundImage: `url(${url})` }}
      />
    ))}
      <Div className="cs-shape_1" />
      <Div className="cs-shape_1" />
      <Div className="cs-shape_1" />
      <Div className="container">
        <Div className="cs-hero_text">
          <h1 className="cs-hero_title">{parse(title)}</h1>
          <h4 className="cs-hero_subtitle">{subtitle}</h4>
            
          <Div className="cs-hero_info">
            <Div>
              <Button btnLink={btnLink} btnText={btnText} />
            </Div>
            
          </Div>
        </Div>
      </Div>
      <VerticalLinks data={heroSocialLinks} title={socialLinksHeading} />
      <a href={scrollDownId} className="cs-down_btn">
        .
      </a>
    </Div>
  );
}
