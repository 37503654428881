import React from 'react';

export const funfaceData = [
    {
      title: 'Vydaných singlů',
      factNumber: '20',
    },
    {
      title: 'm2 prostoru v nahrávací místnosti',
      factNumber: '30',
    },
    {
      title: 'profesionálních nástrojů a zařízení k dispozici pro vaše projekty',
      factNumber: '40',
    },
    {
      title: 'různých zesilovaču, beden a efektů k dispozici pro kytaristy',
      factNumber: '15',
    },
  ];