import React, { useEffect } from 'react'
import { pageTitle } from '../../helper'
import Card from '../Card'
import Cta from '../Cta'
import PageHeading from '../PageHeading'
import PricingTableList from '../PricingTable/PricingTableList'
import Div from '../Div'
import SectionHeading from '../SectionHeading'
import TestimonialSlider from '../Slider/TestimonialSlider'
import Spacing from '../Spacing'
import servicesData from '../../data/servicesData.js'
import { updateMetaTags } from '../../helper/updateMetaTags'

export default function ServicesPage(props) {
  pageTitle('Service');
  useEffect(() => {
    if (props.id === 'cenik') {
      document.getElementById('cenik').scrollIntoView({ behavior: 'smooth' })
    } else {
      window.scrollTo(0, 0)
    }
    
  }, [])
  return (
    <>
      <div>
        {updateMetaTags("Objevte naše služby: nahrávání, mixování, mastering, a produkce. Poskytujeme kompletní audio řešení pro hudebníky a kapely.", " nahrávání, mixování, mastering, hudební produkce, audio služby, Praha")}
      </div>
      <PageHeading 
        title='Services'
        bgSrc='images/service_hero_bg.jpeg'
        pageLinkText='Services'
      />
      <Spacing lg='150' md='80'/>
      <Div className='cs-shape_wrap_4'>
        
        <Div className="container">
          <Div className="row">
            <Div className="col-xl-4">
              <SectionHeading
                title='Služby které vám můžeme nabídnout' 
                subtitle='Stable records studio'
              />
              <Spacing lg='90' md='45'/>
            </Div>
          {/* Start Service Section */}
                    <Div className="col-xl-8">
                      <Div className="row">
                        <Div className="col-lg-3 col-sm-6 cs-hidden_mobile"></Div>
                        <Div className="col-lg-3 col-sm-6">
                          <Card
                            title="Recording"
                            link={"/service/" + servicesData[2].id} 
                            src={servicesData[2].src}
                            alt="Service"
                          />
                          <Spacing lg="0" md="30" />
                        </Div>
                        <Div className="col-lg-3 col-sm-6 cs-hidden_mobile"></Div>
                        <Div className="col-lg-3 col-sm-6">
                          <Card
                            title="MIX & Mastering"
                            link={"/service/" + servicesData[1].id}
                            src={servicesData[1].src}
                            alt="Service"
                          />
                          <Spacing lg="0" md="30" />
                        </Div>
                        <Div className="col-lg-3 col-sm-6">
                          <Card
                            title="Pronájem studia"
                            link={"/service/" + servicesData[3].id}
                            src={servicesData[3].src}
                            alt="Service"
                          />
                          <Spacing lg="0" md="30" />
                        </Div>
                        <Div className="col-lg-3 col-sm-6 cs-hidden_mobile"></Div>
                        <Div className="col-lg-3 col-sm-6">
                          <Card
                            title="Ubytování"
                            link={"/service/" + servicesData[0].id}
                            src={servicesData[0].src}
                            alt="Service"
                          />
                          <Spacing lg="0" md="30" />
                        </Div>
                        <Div className="col-lg-3 col-sm-6 cs-hidden_mobile"></Div>
                      </Div>
                    </Div>
                  
      {/* End Service Section */}

          </Div>
        </Div>
      </Div>
      <div id="cenik">
      <Spacing lg='150' md='80'/>
      <Div className="container">
        <SectionHeading
          title='Snažíme se nabízet co nejlepší služby <br> za co nejlepší cenu' 
          subtitle='Ceník služeb'
        />
        <Spacing lg='85' md='40'/>
        
          <PricingTableList />
        
        
      </Div>
      </div>
      {/* <Spacing lg='125' md='55'/> */}
      {/* <TestimonialSlider/> */}
      <Spacing lg='150' md='55'/>
      <Div className="container">
        <Cta 
          title='Zaujalo vás to? <br />Pojďme vymyslet něco dohromady!' 
          btnText='Ozvěte se nám' 
          btnLink='/contact' 
          bgSrc='/images/cta_bg.jpeg'
        />
      </Div>
    </>
  )
}
