import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { pageTitle } from '../../helper'
import Cta from '../Cta'
import PageHeading from '../PageHeading'
import Div from '../Div'
import SectionHeading from '../SectionHeading'
import Spacing from '../Spacing'
import servicesData from '../../data/servicesData.js';

export default function ServiceDetailsPage() {
  

  
  const params = useParams();
  console.log("here");  
  console.log(params.serviceDetailsId);
  var myServiceData = servicesData.find(item => item.id === params.serviceDetailsId);
  
  console.log(myServiceData);
  pageTitle("page");
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <PageHeading 
        title={myServiceData.name}
        bgSrc='/images/service_hero_bg.jpeg'
        pageLinkText={myServiceData.name}
      />
     
      <Spacing lg='30' md='30'/>
      <Div className="container">
      <Div className="row">
          <Div className="col-xl-5 col-lg-7">
            <SectionHeading
              title={myServiceData.headline}
              subtitle="Stable records studio"
            >
              <Spacing lg="30" md="20" />
              <p className="cs-m0">
              <div dangerouslySetInnerHTML={{ __html: myServiceData.description}} />
              </p>
              <Spacing lg="30" md="30" />
              <Div className="cs-separator cs-accent_bg"></Div>
              <Spacing lg="25" md="40" />
            </SectionHeading>
          </Div>
          <Div className="col-lg-5 offset-xl-2">
            <img
              src={myServiceData.images[1]}
              alt="About"
              className="w-100 cs-radius_15 img-crop"
            />
            <Spacing lg="25" md="25" />
          </Div>
          <Div className="col-lg-7">
            <img
              src={myServiceData.images[2]}
              alt="About"
              className="w-100 cs-radius_15  img-crop"
            />
            <Spacing lg="25" md="25" />
          </Div>
          <Div className="col-lg-5">
            <img
              src={myServiceData.images[3]}
              alt="About"
              className="w-100 cs-radius_15 img-crop"
            />
            <Spacing lg="25" md="25" />
          </Div>
        </Div>
        </Div>
      <Spacing lg='120' md='50'/>
      <Div className="container">
        <Div className="row align-items-center">
          <Div className="col-xl-5 col-lg-6">
            <Div className="cs-radius_15 cs-shine_hover_1">
              <img src={myServiceData.images[0]} alt="Service" className='cs-radius_15 w-100' />
            </Div>
            <Spacing lg='0' md='40'/>
          </Div>
          <Div className="col-lg-6 offset-xl-1">
            <h2 className="cs-font_50 cs-m0">Nabídka zahrnuje:</h2>
            <Spacing lg='50' md='30'/>
            <Div className="row">
            <Div className="col-lg-6">
              <ul>
                {myServiceData.bullets.slice(0, 5).map((bullet, index) => (
                  <React.Fragment key={index}>
                    <li className="cs-bullet" >{bullet}</li>
                    <Spacing lg={index === 5 ? '0' : '20'} md='10'/>
                  </React.Fragment>
                ))}
              </ul>
            </Div>
            <Div className="col-lg-6">
              <ul>
                {myServiceData.bullets.slice(5).map((bullet, index) => (
                  <React.Fragment key={index}>
                    <li className="cs-bullet" >{bullet}</li>
                    <Spacing lg='20' md='10'/>
                  </React.Fragment>
                ))}
              </ul>
            </Div>
            </Div>
          </Div>
        </Div>
      </Div>
      <Spacing lg='150' md='80'/>
      {/* <TestimonialSlider /> */}
      {/* <Spacing lg='145' md='80'/>
      <Div className="container cs-shape_wrap_4">
        <Div className="cs-shape_4"></Div>
        <Div className="cs-shape_4"></Div>
        <Div className="container">
          <Div className="row">
            <Div className="col-xl-5 col-lg-6">
              <SectionHeading
                title='Some pre questions and answers' 
                subtitle='FAQ’s'
              />
              <Spacing lg='90' md='45'/>
            </Div>
            <Div className="col-lg-6 offset-xl-1">
              <Accordion/>
            </Div>
          </Div>
        </Div>
      </Div> */}
      {/* <Spacing lg='150' md='80'/> */}
      <Div className="container">
        <Cta 
          title='Pojď udělat dohromady nějaký hit ;-)' 
          btnText='Napiš te nám' 
          btnLink='/contact' 
          bgSrc='/images/cta_bg.jpeg'
        />
      </Div>
    </>
  )
}
