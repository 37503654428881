export const portfolioData = [
  {
      id: 'tcheichan-jizvy',
      title: 'Tcheichan',
      subtitle: 'Jizvy',
      src: '/images/portfolio/tcheichan-jizvy.jpg',
      videoSrc: "https://www.youtube.com/watch?v=cQDJt9ywkFo",
      category: 'ui_ux_design',
      about: 'Singl Jizvy je produktem kapely Tcheichan, který byl nahrán v našem domovském Stable Records Studio. Píseň byla mixována a masterována Amákem Goldenem. Scénář, režie, kamera a střih videoklipu byly realizovány týmem Story Mages.',
      produkce: 'Stable Records Studio',
      mix_mastering: 'Amák Golden',
      client: 'Tcheichan',
      released: '2022',
    
  },
  {
    id: 'cute-favour-of-god',
    title: 'Cute',
    subtitle: 'Favour of God',
    src: '/images/portfolio/cute-favour-of-god.jpg',
    videoSrc: 'https://youtu.be/watch?v=KM05rzISu6c',
    category: 'logo_design',
    about: 'Cover singlu Favour of God od kapely Cute.',
    produkce: 'Cute',
    mix_mastering: 'Cute',
    client: 'Cute',
    released: '2022',
  },
  {
      id: 'adela-koudelkova-otazky',
      title: 'Adéla Koudelková',
      subtitle: 'Otázky',
      src: '/images/portfolio/adela-koudelkova-otazky.jpg',
      videoSrc: 'https://www.youtube.com/watch?v=UJsFSAl9nFo',
      category: 'logo_design',
      about: 'Singl Otázky je třetí písní z alba Úsměvy od Adély Koudelkové. Nahrávka byla produkována a mixována ve Stable Records Studio. Scénář, režie, kamera a střih videoklipu byly realizovány týmem Framewall Films.',
      produkce: 'Stable Records Studio',
      mix_mastering: 'Stable Records Studio',
      client: 'Adéla Koudelková',
      released: '2023',
  
  },
  {
    id: 'iggy-roztoc-svet',
    title: 'IGGY',
    subtitle: 'Roztoč Svět',
    src: '/images/portfolio/roztoc-svet.jpg',
    category: 'web_design',
    videoSrc: 'https://www.youtube.com/watch?v=kiuCfG4YNkI',
    about: 'Singl Roztoč Svět je dynamická skladba od umělce IGGY. Píseň byla nahrána a produkována ve Stable Records Studio. Scénář, střih a editace videa byly realizovány samotným IGGYm.',
    produkce: 'Stable Records Studio',
    mix_mastering: 'Stable Records Studio',
    client: 'IGGY',
    released: '2024',
  },
  {
    id: 'adela-koudelkova-treba-nekdy',
    title: 'Adéla Koudelková',
    subtitle: 'Třeba někdy',
    src: '/images/portfolio/adela-koudelkova-treba-nekdy.jpg',
    category: 'Produkce, recording, mix, mastering,',
    videoSrc: 'https://www.youtube.com/watch?v=06TiN9yZH_k',
    about: 'Singl Třeba někdy je druhou písní z alba Úsměvy od Adély Koudelkové. Nahrávka byla produkována a mixována ve Stable Records Studio. Scénář, režie, kamera a střih videoklipu byly realizovány týmem Framewall Films.',
    produkce: 'Stable Records Studio',
    mix_mastering: 'Stable Records Studio',
    client: 'Adéla Koudelková',
    released: '2022',
  },
  {
    id: 'tcheichan-proti-sobe',
    title: 'Tcheichan',
    subtitle: 'Proti sobě',
    src: '/images/portfolio/tcheichan-proti-sobe.jpg',
    category: 'web_design',
    videoSrc: 'https://youtu.be/watch?v=9umWXRvQrBw',
    about: 'Singl Proti sobě je druhým singlem z připravované druhé desky kapely Tcheichan. Nahrávka byla produkována a mixována ve Stable Records Studio. Scénář, režie, kamera a střih videoklipu byly realizovány Janem Frýbou, se speciálními světelnými efekty od Štěpána Němce.',
    produkce: 'Stable Records Studio',
    mix_mastering: 'Stable Records Studio',
    client: 'Tcheichan',
    released: '2022',
  },
  {
    id: 'adela-koudelkova-usmevy',
    title: 'Adéla Koudelková',
    subtitle: 'Úsměvy',
    src: '/images/portfolio/adela-koudelkova-usmevy.jpg',
    category: 'ui_ux_design',
    videoSrc: 'https://www.youtube.com/watch?v=DvvEdkFU0KQ',
    about: 'Singl Úsměvy je titulní písní z alba Úsměvy od Adély Koudelkové. Nahrávka byla produkována a mixována ve Stable Records Studio. Scénář, režie, kamera a střih videoklipu byly realizovány Janem Frýbou.',
    produkce: 'Stable Records Studio',
    mix_mastering: 'Stable Records Studio',
    client: 'Adéla Koudelková',
    released: '2022',
  }
];