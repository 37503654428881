import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { pageTitle } from '../../helper'
import Cta from '../Cta'
import PageHeading from '../PageHeading'
import Div from '../Div'
import SectionHeading from '../SectionHeading'
import Spacing from '../Spacing'
import VideoModal from '../VideoModal'
import {portfolioData} from '../../data/portfolioData.js'
export default function PortfolioDetailsPage() {
  const params = useParams()
  const portfolioDetails = params.portfolioDetailsId 
  ? portfolioData.find(item => item.id === params.portfolioDetailsId)
  : null;
  console.log(portfolioDetails.videoSrc);
  
  pageTitle(portfolioDetails.title);
  useEffect(() => {
    
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <PageHeading 
        title={portfolioDetails.title + " - " + portfolioDetails.subtitle}
        bgSrc='/images/service_hero_bg.jpeg'
        pageLinkText={portfolioDetails.title}
        style={{ color: 'red' }}
      />
      <Spacing lg='150' md='80'/>
      <Div className="container">
    
      <VideoModal
          videoSrc={portfolioDetails.videoSrc}
          bgUrl={portfolioDetails.src}
        />  
        <Spacing lg='90' md='40'/>
        <Div className="row">
          <Div className="col-lg-6">
            <SectionHeading 
              title={portfolioDetails.title + " - " + portfolioDetails.subtitle}
              subtitle='Creative' 
            >
              <Spacing lg='40' md='20'/>
              <p>{portfolioDetails.about}</p>
              
            </SectionHeading>
          </Div>
          <Div className="col-lg-5 offset-lg-1">
            <Spacing lg='60' md='40'/>
            <h2 className='cs-font_30 cs-font_26_sm cs-m0'>Project Info -</h2>
            <Spacing lg='50' md='30'/>
            <Div className="row">
              <Div className="col-6">
                <h3 className='cs-accent_color cs-font_22 cs-font_18_sm cs-m0'>Produkce:</h3>
                <p className='cs-m0'>{portfolioDetails.produkce}</p>
                <Spacing lg='30' md='30'/>
              </Div>
              <Div className="col-6">
                <h3 className='cs-accent_color cs-font_22 cs-font_18_sm cs-m0'>Mix&Mastering:</h3>
                <p className='cs-m0'>{portfolioDetails.mix_mastering}</p>
                <Spacing lg='30' md='30'/>
              </Div>
            
              <Div className="col-6">
                <h3 className='cs-accent_color cs-font_22 cs-font_18_sm cs-m0'>Datum:</h3>
                <p className='cs-m0'>{portfolioDetails.released}</p>
                <Spacing lg='30' md='30'/>
              </Div>
              <Div className="col-6">
                <h3 className='cs-accent_color cs-font_22 cs-font_18_sm cs-m0'>Klient:</h3>
                <p className='cs-m0'>{portfolioDetails.client}</p>
                <Spacing lg='30' md='30'/>
              </Div>
            </Div>
          </Div>
        </Div>
        {/* <Spacing lg='65' md='10'/>
          <Div className="cs-page_navigation cs-center">
            <Div>
              <Button btnLink='/portfolio/portfolio-details' btnText='Prev Project' variant='cs-type1'/>
            </Div>
            <Div>
              <Button btnLink='/portfolio/portfolio-details' btnText='Next Project'/>
            </Div>
          </Div> */}
      </Div>
      <Spacing lg='145' md='80'/>
      <Cta 
        title='studio@stablerecords.cz' 
        bgSrc='/images/cta_bg_2.jpeg'
        variant='rounded-0'
      />
    </>
  )
}
