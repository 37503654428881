import React from 'react'
import { useState, useEffect } from 'react'
import PricingTable from '.'
import Section from '../Div'
import Spacing from '../Spacing'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBolt, faCoffee, faBeer, faMicrophone } from '@fortawesome/free-solid-svg-icons';
import { useBtcRate } from '../../BtcRateContext';

export default function PricingTableList() {
  const [tab, setTab] = useState('monthly')
  const [currency, setCurrency] = useState('BTC')
  const btcRate = useBtcRate();

  const convertToBtc = (value) => {
    return btcRate === 0 ? 0 : parseFloat(value/btcRate).toFixed(5);
  }

  



  return (
    <Section className="position-relative">
      <ul className="cs-tab_links cs-style1 cs-mp0 cs-primary_font">
        <li className={tab === 'monthly' ? "active" : ""} onClick={()=>setTab('monthly')}>Všední dny</li>
        <li className={tab === 'yearly' ? "active" : ""} onClick={()=>setTab('yearly')}>Víkedny/svátky</li>
      </ul> 
    
    
     
      
      <ul className="cs-tab_links cs-style-btc-czk cs-mp0 cs-primary_font">
        <li className={currency === 'BTC' ? "active" : ""} onClick={()=>setCurrency('BTC')}>Bitcoin</li>
        <li className={currency === 'CZK' ? "active" : ""} onClick={()=>setCurrency('CZK')}>CZK</li>
      </ul> 
        
      

      <Section className="row">
        <Section className="col-lg-4">
          {tab==='monthly' && (
            <PricingTable 
              title='Pronájem studia'
              subline='Hodinové projekty'
              price={currency === 'BTC' ? convertToBtc(300) : 300}
              currency={currency === 'BTC' ? ' BTC' : 'Kč'}
              timeline='hodina'
              features={['Celé studio pro váš projekt','Ideální pro podcasty', 'Kompletní vybavení k dispozici', 'Možnosti zaškolení', ]}
              btnText='Rezervujte si termín'
              btnLink='/contact'
            />
          )}
          {tab==='yearly' && (
            <PricingTable 
              title='Pronájem studia'
              subline='Hodinové projekty'
              price={currency === 'BTC' ? convertToBtc(350) : 350}
              currency={currency === 'BTC' ? ' BTC' : 'Kč'}
              timeline='hodina'
              features={['Celé studio pro váš projekt','Ideální pro podcasty', 'Kompletní vybavení k dispozici', 'Možnosti zaškolení', ]}
              btnText='Rezevujte si termín'
              btnLink='/contact'
            />
          )}
          <Spacing lg='25' md='25'/>
        </Section>
        <Section className="col-lg-4">
         {tab==='monthly' && (
            <PricingTable 
              title='Pronájem na den'
              subline='Celý den 8-22h'
              price={currency === 'BTC' ? convertToBtc(2000) : 2000}
              currency={currency === 'BTC' ? ' BTC' : 'Kč'}
              timeline='Den'
              features={['Celé studio pro váš projekt', 'Kompletní vybavení k dispozici', 'Možnost ubytování v ceně', 'Možnost zaškolení', 'Možnost technické asistence na místě' ]}
              btnText='Rezevujte si termín'
              btnLink='/contact'
            />
          )}
          {tab==='yearly' && (
            <PricingTable 
            title='Pronájem na den'
              subline='Celý den 8-22h'
              price={currency === 'BTC' ? convertToBtc(2500) : 2500}
              currency={currency === 'BTC' ? ' BTC' : 'Kč'}
              timeline='Den'
              features={['Celé studio pro váš projekt', 'Kompletní vybavení k dispozici', 'Možnost ubytování v ceně', 'Možnost zaškolení', 'Možnost technické asistence na místě' ]}
              btnText='Rezevujte si termín'
              btnLink='/contact'
            />
          )}
          <Spacing lg='25' md='25'/>
        </Section>
        <Section className="col-lg-4">
          {tab==='monthly' && (
            <PricingTable 
              title='Full service'
              subline='Kompletní služby'
              price={currency === 'BTC' ? convertToBtc(650) : 650}
              currency={currency === 'BTC' ? ' BTC' : 'Kč'}
              timeline='za hodinu'
              features={['Produkce', 'Editing', 'Recording', 'Mix & Master', 'Konzultace vašich aranží', 'Ubytování v ceně']}
              btnText='Rezevujte si termín'
              btnLink='/contact'
            />
          )}
          {tab==='yearly' && (
            <PricingTable 
            title='Full service'
            subline='Kompletní služby'
            price={currency === 'BTC' ? convertToBtc(600) : 600}
            currency={currency === 'BTC' ? ' BTC' : 'Kč'}
            timeline='za hodinu'
            features={['Produkce', 'Editing', 'Recording', 'Mix & Master', 'Konzultace vašich aranží', 'Ubytování v ceně']}
            btnText='Rezevujte si termín'
            btnLink='/contact'
            />
          )}
          <Spacing lg='25' md='25'/>

        </Section>

      </Section>
      <Section className="row">
      <p>Pokud nás chcete podpořit v tom, co děláme, a poslat nám nějaké drobné satoshi na kafe <FontAwesomeIcon icon={faCoffee}/>, pivo <FontAwesomeIcon icon={faBeer}/> nebo nějaký vybavení <FontAwesomeIcon icon={faMicrophone}/> můžete tak učinit zde:</p>
      <Section className="col-lg-4">
        {/* Přidání odkazu na lightning peněženku */}
      
        <img className="lightning-qr" src={'images/services/satoshi.PNG'} alt="Podpořte nás" />
        </Section>
      </Section>
    </Section>
  )
}
