import { Icon } from '@iconify/react';
import React, { useEffect, useState } from 'react';
import { pageTitle } from '../../helper';
import Div from '../Div';
import PageHeading from '../PageHeading';
import SectionHeading from '../SectionHeading';
import Spacing from '../Spacing';
import ContactInfoWidget from '../Widget/ContactInfoWidget';
import axios from 'axios';
import Spinner from '../Spinner';
import Swal from 'sweetalert2';
import { updateMetaTags } from '../../helper/updateMetaTags';


/**
 * ContactPage component represents the contact page of the application.
 * It allows users to fill in a contact form and send a message.
 *
 * @returns {JSX.Element} The ContactPage component
 */
export default function ContactPage() {
  const [from, setFrom] = useState('');
  const [name, setName] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [phone, setPhone] = useState('');
  const [status, setStatus] = useState('');
  const [loading, setLoading] = useState(false);

  const config = require('../../config');

  
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    // Validation
    const emailRegex = /\S+@\S+\.\S+/;
    const phoneRegex = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/;

    if (!name || !from || !subject || !message) {
        Swal.fire({
            title: 'Error!',
            text: 'Please fill in all fields.',
            icon: 'error',
            confirmButtonText: 'OK'
        });
        setLoading(false);
        return;
    }

    if (!emailRegex.test(from)) {
        Swal.fire({
            title: 'Error!',
            text: 'Zadejte správný email.',
            icon: 'error',
            confirmButtonText: 'OK'
        });
        setLoading(false);
        return;
    }

    if (!phoneRegex.test(phone)) {
        Swal.fire({
            title: 'Error!',
            text: 'prosím zadejte tel. číslo v mezinárodním formátu.',
            icon: 'error',
            confirmButtonText: 'OK'
        });
        setLoading(false);
        return;
    }

    try {
        const response = await axios.post(config[process.env.NODE_ENV].apiUrl + "send_email.php", {
            from,
            name,
            subject,
            message,
            phone,
        });
        setStatus(response.data.message);
        Swal.fire({
          title: 'Success!',
          text: 'Email sent successfully!',
          icon: 'success',
          confirmButtonText: 'OK'
        }).then((result) => {
          if (result.isConfirmed) {
            setName('');
            setFrom('');
            setSubject('');
            setMessage('');
            setPhone(''); 
          }
        });
    } catch (error) {
        await setStatus('Nějaká chyba se stala, zkuste to znovu prosím');
        console.log(status);
        Swal.fire({
          title: 'Error!',
          text: status,
          icon: 'error',
          confirmButtonText: 'OK'
        });
    } finally {
        setLoading(false);
    }
};
//
  const handleChange = (e) => {
    switch (e.target.name) {
      case 'name':
        setName(e.target.value);
        break;
      case 'from':
        setFrom(e.target.value);
        break;
      case 'subject':
        setSubject(e.target.value);
        break;
      case 'message':
        setMessage(e.target.value);
        break;
      case 'phone':
        setPhone(e.target.value);
        break;
      default:
        break;
    }
  };

  pageTitle('Contact Us');
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
    <div>
      {updateMetaTags("Kontaktujte nás pro více informací nebo rezervaci termínu. Jsme zde, abychom vám pomohli s vašimi hudebními projekty.", "kontakt, rezervace, nahrávací studio Praha, hudební projekty")}
    </div>
     {loading && <Spinner />}

      <PageHeading
        title="Kontaktujte nás"
        bgSrc="/images/contact_hero_bg.jpeg"
        pageLinkText="Kontakt"
      />
      <Spacing lg="150" md="80" />
      <Div className="container">
        <Div className="row">
          <Div className="col-lg-6">
            <SectionHeading
              title="Máte nápad na konkrétní projekt?"
              subtitle="Dejte nám vědět"
            />
            <Spacing lg="55" md="30" />
            <ContactInfoWidget withIcon />
            <Spacing lg="0" md="50" />
          </Div>
          <Div className="col-lg-6">
            <form onSubmit={handleSubmit} className="row">
              <Div className="col-sm-6">
                <label className="cs-primary_color">Jméno a Příjmení*</label>
                <input type="text" className="cs-form_field" name="name" value={name} onChange={handleChange} />
                <Spacing lg="20" md="20" />
              </Div>
              <Div className="col-sm-6">
                <label className="cs-primary_color">Email*</label>
                <input type="text" className="cs-form_field" name="from" value={from} onChange={handleChange}  />
                <Spacing lg="20" md="20" />
              </Div>
              <Div className="col-sm-6">
                <label className="cs-primary_color">Typ projektu*</label>
                <input type="text" className="cs-form_field" name="subject" value={subject} onChange={handleChange} />
                <Spacing lg="20" md="20" />
              </Div>
              <Div className="col-sm-6">
                <label className="cs-primary_color" >Telefon*</label>
                <input type="text" className="cs-form_field" name="phone" value={phone} onChange={handleChange}/>
                <Spacing lg="20" md="20" />
              </Div>
              <Div className="col-sm-12">
                <label className="cs-primary_color">Poznámka*</label>
                <textarea
                  cols="30"
                  rows="7"
                  className="cs-form_field"
                  name="message" value={message} onChange={handleChange} 
                ></textarea>
                <Spacing lg="25" md="25" />
              </Div>
              <Div className="col-sm-12">
                <button className="cs-btn cs-style1">
                  <span>Send Message</span>
                  <Icon icon="bi:arrow-right" />
                </button>
              </Div>
            </form>
          </Div>
        </Div>
      </Div>
      <Spacing lg="150" md="80" />
      <Div className="cs-google_map">

<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d164470.08693297097!2d14.245510600747938!3d49.90171121726089!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470b9b04dd3f033b%3A0xf786ce5d44e90a7a!2sStable%20Records%20Studio!5e0!3m2!1scs!2scz!4v1714124088316!5m2!1scs!2scz" 

allowFullScreen="" 
loading="lazy" 
referrerPolicy="no-referrer-when-downgrade"
title="Google Map"
/>
  
      </Div>
      <Spacing lg="50" md="40" />
    </>
  );
}
