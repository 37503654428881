import React, { createContext, useState, useContext, useEffect } from 'react';

const BtcRateContext = createContext();

export const useBtcRate = () => useContext(BtcRateContext);

export const BtcRateProvider = ({ children }) => {
  const [btcRate, setBtcRate] = useState(0);

  useEffect(() => {
    const fetchBtcRate = async () => {
      try {
        const response = await fetch('https://api.coingecko.com/api/v3/simple/price?ids=bitcoin&vs_currencies=CZK');
        const data = await response.json();
        setBtcRate(data.bitcoin.czk);
        console.log('Kurz Bitcoinu:', data.bitcoin.czk);
      } catch (error) {
        console.error('Chyba při načítání kurzu Bitcoinu:', error);
      }
    };

    fetchBtcRate();
  }, []);

  return (
    <BtcRateContext.Provider value={btcRate}>
      {children}
    </BtcRateContext.Provider>
  );
};