import React from 'react';
import eqipmentListData from '../../data/equipmentListData';
import './equipment.scss';


function EquipmentTable() {
  return (
    <div>
      {eqipmentListData.map((category, index) => (
        <div key={index}>
          <h2>{category.category}</h2>
          <table>
            <thead>
              <tr>
                <th className='strong'><h4>Výrobce</h4></th>
                <th ><h4>Typ</h4></th>
                <th><h4>Popis</h4></th>
              </tr>
            </thead>
            <tbody>
              {category.items.map((item, index) => (
                <tr key={index}>
                  <td>{item.vyrobce}</td>
                  <td>{item.typ}</td>
                  <td>{item.popis}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ))}
    </div>
  );
}

export default EquipmentTable;