import React, { useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import Div from '../Div';

export default function Newsletter({title, subtitle, placeholder}) {
  const [email, setEmail] = useState('');
  const config = require('../../config');

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      Swal.fire('Error', 'Invalid email format', 'error');
      return;
    }

    try {
      const response = await axios.post(config[process.env.NODE_ENV].apiUrl + "newsletter.php", {
        email: email
      });

      if (response.data.status === 'success') {
        Swal.fire('Success', 'Email saved successfully', 'success');
      } else {
        Swal.fire('Error', 'Failed to save email', 'error');
      }
    } catch (error) {
      Swal.fire('Error', 'An error occurred', 'error');
    }
  };

  return (
    <>
      {title && <h2 className="cs-widget_title">{title}</h2>}
      <Div className="cs-newsletter cs-style1">
        <form action="#" className="cs-newsletter_form" onSubmit={handleSubmit}>
          <input 
            type="email" 
            className="cs-newsletter_input" 
            placeholder={placeholder} 
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
          <button className="cs-newsletter_btn"><span>Send</span></button>
        </form>
        <Div className="cs-newsletter_text">{subtitle}</Div>
      </Div>
    </>
  )
}